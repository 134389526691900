import {
  Editor,
  Element,
  Range,
  Text,
  Transforms,
  Node as SlateNode,
  Path,
} from 'slate'

export function withLinks(editor) {
  const { isInline: original } = editor

  return Object.assign(editor, {
    isInline: (element) => element.type === 'link' || original(element),
  })
}

export function withSingleWord(editor) {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    // Prevent top level from being split into multiple nodes with the enter key.
    if (Editor.isEditor(node)) {
      for (const [child, childPath] of SlateNode.children(editor, path, {
        reverse: true,
      })) {
        if (Element.isElement(child)) {
          Transforms.mergeNodes(editor, { at: childPath })
          return
        }
      }
    }

    // Strip any whitespace from text leaf nodes
    if (Text.isText(node)) {
      const nonWhitespaceText = node.text.replace(/\s/g, '')
      if (nonWhitespaceText !== node.text) {
        Transforms.insertText(editor, nonWhitespaceText, { at: path })
        return
      }
    }

    return normalizeNode([node, path])
  }

  return editor
}

export function withNormalizedLists(editor) {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    if (Element.isElement(node) && node.type === 'unordered-list') {
      for (const [child, childPath] of SlateNode.children(editor, path)) {
        if (!Element.isElement(child)) continue

        if (child.type === 'unordered-list') {
          Transforms.unwrapNodes(editor, { at: childPath })
          return
        }

        if (child.type !== 'list-item') {
          Transforms.setNodes(editor, { type: 'list-item' }, { at: childPath })
          return
        }
      }
    }

    if (Element.isElement(node) && node.type === 'list-item') {
      if (SlateNode.parent(editor, path).type !== 'unordered-list') {
        Transforms.wrapNodes(
          editor,
          {
            type: 'unordered-list',
          },
          {
            at: Path.parent(path),
            match: (n) => Element.isElement(n) && n.type === 'list-item',
          }
        )
        return
      }
    }

    return normalizeNode([node, path])
  }

  return editor
}

export function withFlatText(editor) {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    if (Element.isElement(node)) {
      if (node.type !== 'paragraph') {
        Transforms.setNodes(editor, { type: 'paragraph' }, { at: path })
        return
      }

      for (const [child, childPath] of SlateNode.children(editor, path)) {
        if (Element.isElement(child)) {
          Transforms.unwrapNodes(editor, { at: childPath })
          return
        }
      }
    }

    return normalizeNode([node, path])
  }

  return editor
}

export function deserialize(html) {
  return deserializeDomNode(
    new DOMParser().parseFromString(html, 'text/html').body
  )
}

function deserializeDomNode(domNode) {
  switch (domNode.nodeType) {
    case Node.ELEMENT_NODE:
      return deserializeDomElement(domNode)
    case Node.TEXT_NODE:
      return deserializeDomText(domNode)
    default:
      throw 'Expected DOM node to be either Element or Text'
  }
}

function deserializeDomElement(domElement) {
  if (domElement.childNodes.length <= 0) {
    // Slate cannot handle empty elements, so we add an empty text.
    domElement.appendChild(domElement.ownerDocument.createTextNode(''))
  }

  const children = Array.from(domElement.childNodes).map(deserializeDomNode)

  switch (domElement.tagName) {
    case 'A':
      return {
        type: 'link',
        url: domElement.getAttribute('href') || '',
        children,
      }
    case 'BODY':
      return [...children]
    case 'LI':
      return { type: 'list-item', children }
    case 'P':
      return { type: 'paragraph', children }
    case 'UL':
      return { type: 'unordered-list', children }
    default:
      throw `Unexpected tag name "${domElement.tagName}"`
  }
}

function deserializeDomText(domText) {
  return { text: domText.data }
}

export function serialize(slateNodes) {
  return slateNodes.reduce(serializeSlateNode, document.createElement('DIV'))
    .innerHTML
}

function serializeSlateNode(domParent, slateNode) {
  switch (true) {
    case Element.isElement(slateNode):
      return serializeSlateElement(domParent, slateNode)
    case Text.isText(slateNode):
      return serializeSlateText(domParent, slateNode)
    default:
      throw 'Expected Slate node to be either Element or Text'
  }
}

function serializeSlateElement(domParent, slateElement) {
  let domElement
  switch (slateElement.type) {
    case 'link':
      domElement = domParent.ownerDocument.createElement('A')
      domElement.setAttribute('href', slateElement.url || '')
      break
    case 'list-item':
      domElement = domParent.ownerDocument.createElement('LI')
      break
    case 'paragraph':
      domElement = domParent.ownerDocument.createElement('P')
      break
    case 'unordered-list':
      domElement = domParent.ownerDocument.createElement('UL')
      break
    default:
      throw `Unexpected Slate element type "${slateElement.type}"`
  }

  domParent.appendChild(
    slateElement.children.reduce(serializeSlateNode, domElement)
  )

  return domParent
}

function serializeSlateText(domParent, slateText) {
  domParent.appendChild(domParent.ownerDocument.createTextNode(slateText.text))

  return domParent
}

export function isElementAt(editor, type, at) {
  const entries = Editor.nodes(editor, {
    at,
    match: (node) => Element.isElement(node) && node.type === type,
  })

  return !entries.next().done // at least one entry found
}

export function isOnlyHighestElementAt(editor, type) {
  let foundType = false
  let foundOther = false

  for (const [node, _path] of Editor.nodes(editor, {
    match: (node) => Element.isElement(node),
    mode: 'highest',
  })) {
    if (node.type === type) foundType = true
    else if (node.type !== type) foundOther = true

    if (foundType && foundOther) break
  }

  return foundType && !foundOther
}

export function createLink(editor) {
  if (Range.isCollapsed(editor.selection)) return

  Transforms.unwrapNodes(editor, {
    match: (node) => Element.isElement(node) && node.type === 'link',
    split: true,
  })

  Transforms.wrapNodes(
    editor,
    {
      type: 'link',
      children: [],
    },
    {
      split: true,
      match: (node) => Text.isText(node) && node.text !== '',
    }
  )
}

export function removeLink(editor) {
  Transforms.unwrapNodes(editor, {
    match: (node) => Element.isElement(node) && node.type === 'link',
    // XXX Split would be more user friendly,
    // but it does not break text / inline nodes.
    // Keeping it around in case it starts working.
    split: true,
    mode: 'all',
  })
}

export function getLinkUrl(editor, at) {
  const entries = Editor.nodes(editor, {
    at,
    match: (node) => Element.isElement(node) && node.type === 'link',
  })

  let same = true
  let url = null
  for (const [node, _path] of entries) {
    if (url === null) {
      url = node.url
    } else if (url !== node.url) {
      same = false
      break
    }
  }

  return [same, url || '']
}

export function setLinkUrl(editor, url, at) {
  Transforms.setNodes(
    editor,
    { url },
    {
      at,
      match: (node) => Element.isElement(node) && node.type === 'link',
    }
  )
}

export function changeToParagraph(editor) {
  Transforms.unwrapNodes(editor, {
    match: (node) => Element.isElement(node) && node.type === 'unordered-list',
    split: true,
  })

  Transforms.setNodes(
    editor,
    {
      type: 'paragraph',
    },
    {
      match: (node) => Element.isElement(node) && node.type === 'list-item',
    }
  )
}

export function changeToUnorderedList(editor) {
  Transforms.unwrapNodes(editor, {
    match: (node) => Element.isElement(node) && node.type === 'unordered-list',
    split: true,
  })

  Transforms.setNodes(
    editor,
    {
      type: 'list-item',
    },
    {
      match: (node) => Element.isElement(node) && node.type === 'paragraph',
    }
  )

  Transforms.wrapNodes(editor, {
    type: 'unordered-list',
    children: [],
  })
}
